import {
  DKButton,
  DKCalendar,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKListPicker2,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader,
  showToast
} from 'deskera-ui-library';
import { forEach, map } from 'lodash';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ic_calender from '../../Assets/Icons/ic_calendar.png';
import { triggerDownload } from '../../Components/ImportExport/utility/ExportData';
import BuildAssembly from '../../Components/Product/BuildAssembly/BuildAssembly';
import { allowedDocumentsForFulfillmentQC } from '../../Components/QualityCheck/QualityCheckConstant';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { QC_FLOW_MODULES } from '../../Components/Settings/QcFlow/QCFlowConstant';
import {
  BOOKS_ADDRESS_TYPES,
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  FULFILLMENT_TYPE,
  INPUT_VIEW_DIRECTION,
  LABELS,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  QTY_ROUNDOFF_PRECISION_BACKEND,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import useQCConfirm from '../../Hooks/useQCConfirm';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import { DraftTypes } from '../../Models/Drafts';
import {
  FulfillmentItemState,
  FulfillmentPayload,
  FulfillmentPopupProps,
  FulfillmentState
} from '../../Models/Fulfillment';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { selectCustomFields } from '../../Redux/Slices/CommonDataSlice';
import {
  createBlankDraft,
  draftTableId,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { selectInventorysColumnConfig } from '../../Redux/Slices/InventorySlice';
import {
  fetchInvoices,
  selectInvoicesColumnConfig,
  selectInvoicesColumnConfigTableId
} from '../../Redux/Slices/InvoicesSlice';
import {
  fetchQuotes,
  selectQuotesColumnConfig,
  selectQuotesColumnConfigTableId
} from '../../Redux/Slices/QuotesSlice';
import {
  fetchSalesOrders,
  selectSalesOrderColumnConfig,
  selectSalesOrderColumnConfigTableId
} from '../../Redux/Slices/SalesOrderSlice';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import AttachmentService from '../../Services/Attachment';
import DateFormatService from '../../Services/DateFormat';
import FulillmentMasterService from '../../Services/FulfillmentMasterService';
import FulfillmentService from '../../Services/FulfillmentService';
import { localizedText } from '../../Services/Localization/Localization';
import ProductService from '../../Services/Product';
import CommonQualityCheckService from '../../Services/QualityCheck';
import QuotationService from '../../Services/Quotation';
import SalesOrderService from '../../Services/SalesOrder';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import {
  customFieldsContainsErrors,
  getFormattedAddress
} from '../DocumentForm/NewDocumentHelper';
import FulfillmentGrid from './FulfillmentGrid';
import { setInitialDataForLocalizedUOM } from './FulfilmentGridHelper';

const Fulfillment: React.FC<FulfillmentPopupProps> = (props) => {
  const tenantInfo = useAppSelector(activeTenantInfo);

  const currentDate = new Date();
  const [fulfillmentDate, setFulfillmentDate] = useState<Date>(currentDate);
  const [fulfillmentDateOpen, setFulfillmentDateOpen] = useState(false);
  const [showBuildAssemblyPopup, setShowBuildAssemblyPopup] = useState(false);
  const [assemblyProduct, setAssemblyProduct] = useState<any>({});
  const [childColumnConfig, setChildColumnConfig] = useState<any>([]);

  const getFulfillmentItems = () => {
    let updatedItems: FulfillmentState['fulfillmentItems'] = [];
    if (props?.documentDetails?.salesInvoiceItems?.length) {
      updatedItems = [...props.documentDetails.salesInvoiceItems];
    } else if (props?.documentDetails?.quotationItemDtoList?.length) {
      updatedItems = props.documentDetails.quotationItemDtoList.filter(
        (item: any) => !item.optional
      );
    } else if (props?.documentDetails?.salesOrderItems?.length) {
      updatedItems = [...props.documentDetails.salesOrderItems];
    }

    if (updatedItems?.length) {
      updatedItems.forEach((item) => {
        item.pendingQuantity = Utility.getPendingQuantity(item);
      });
    }

    // for localized uom
    updatedItems = updatedItems?.map((item: any) => {
      const docSchema =
        item?.unlocalizedDocumentUOMSchemaDefinition ??
        item?.documentUOMSchemaDefinition ??
        null;
      if (
        (item?.isLocalizedUomQty ||
          tenantInfo?.additionalSettings?.LOCALIZED_UOM_CONVERSION) &&
        Utility.isNotEmpty(docSchema)
      ) {
        return setInitialDataForLocalizedUOM(item, DOC_TYPE.FULFILLMENT);
      } else {
        return item;
      }
    });

    return updatedItems;
  };

  const getDocDetailsForFulFillment = () => {
    let fulfillmentDocDetails = props.documentDetails;

    if (
      props.documentType === DOC_TYPE.QUOTE &&
      Utility.isNotEmpty(fulfillmentDocDetails?.quotationItemDtoList)
    ) {
      fulfillmentDocDetails = {
        ...fulfillmentDocDetails,
        items: fulfillmentDocDetails.items?.filter(
          (item: any) => !item.optional
        ),
        quotationItemDtoList: getFulfillmentItems()
      };
    }

    return fulfillmentDocDetails;
  };

  const [documentDetails, setDocumentDetails] = useState(
    getDocDetailsForFulFillment()
  );
  const docType = props.documentType;
  const [warehouseCode, setWarehousecode] = useState('');
  const [completeProcess, setCompleteProcess] = useState(false);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [defaultWarehouse, setDefaultWarehouse] = useState<any>({});

  const invoiceConfigTableId = useAppSelector(
    selectInvoicesColumnConfigTableId
  );
  const quotationConfigTableId = useAppSelector(
    selectQuotesColumnConfigTableId
  );
  const soConfigTableId: any = useAppSelector(
    selectSalesOrderColumnConfigTableId
  );
  const columnConfigForInvoice: any = useAppSelector(
    selectInventorysColumnConfig
  );
  const columnConfigForQuotation: any = useAppSelector(
    selectQuotesColumnConfig
  );
  const columnConfigForSO: any = useAppSelector(selectSalesOrderColumnConfig);
  let module: any = MODULES_NAME.INVOICE;
  let colConfig: any = columnConfigForInvoice;
  let configTableId: any = invoiceConfigTableId;
  const lineItemsTable = useRef(null);
  const [fulfillmentItems, setFulfillmentItems] = useState<
    FulfillmentState['fulfillmentItems']
  >(getFulfillmentItems());
  const [quotationItems, setQuotationItems] = useState<any[]>([]);
  const [salesOrderItems, setSalesOrderItems] = useState<any[]>([]);
  const [isBackOrder] = useState(props?.documentDetails?.backOrder);
  const [backOrderNeeded, setBackOrderNeeded] = useState(false);
  const [isDropShip, setIsDropShip] = useState(props.isFulfillmentForDropship);

  const [displayError, setDisplayError] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');

  const [warehouseProduct, setWarehouseProduct] = useState<any[]>([]);
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  // const [isVisibleState, setIsVisibleState] = useState(false);
  // const [hasFulfillmentError, setHasFulfillmentError] = useState(false);
  const [isPartialInvoice, setIsPartialInvoice] = useState(false);
  const [isPartialSalesOrder, setIsPartialSalesOrder] = useState(false);

  const dispatch = useAppDispatch();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [newAttachments, setNewAttachments] = useState<any[]>([]);
  const [isRemovingAttachment, setIsRemovingAttachment] = useState(false);
  const [assignedAdvancedData, setAssignedAdvancedData] = useState([]);

  const [currentFromAddress, setCurrentFromAddress] = useState<any>(null);
  const [currentToAddress, setCurrentToAddress] = useState<any>(null);
  const [openAddressPicker, setOpenAddressPicker] = useState<any>(null);
  const [isFromAddressUpdated, setIsFromAddressUpdated] = useState(false);
  const [isToAddressUpdated, setIsToAddressUpdated] = useState(false);

  const invoiceColumnConfig = useAppSelector(selectInvoicesColumnConfig);
  const invoiceDocConfigTableId = useAppSelector(
    selectInvoicesColumnConfigTableId
  );
  const quoteColumnConfig = useAppSelector(selectQuotesColumnConfig);
  const quoteConfigTableId = useAppSelector(selectQuotesColumnConfigTableId);
  const salesOrderColumnConfig = useAppSelector(selectSalesOrderColumnConfig);
  const salesOrderConfigTableId = useAppSelector(
    selectSalesOrderColumnConfigTableId
  );
  const [isWarehouseAPILoaded, setIsWarehouseAPILoaded] = useState(false);
  const [isLinkedDocAPILoaded, setIsLinkedDocAPILoaded] = useState(false);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);

  if (props.documentType === 'SALES_ORDER') {
    colConfig = columnConfigForSO;
    configTableId = soConfigTableId;
    module = MODULES_NAME.SALESORDER;
  } else if (props.documentType === 'QUOTATION') {
    colConfig = columnConfigForQuotation;
    configTableId = quotationConfigTableId;
    module = MODULES_NAME.QUOTATION;
  }

  const { qcConfirm } = useQCConfirm();

  useEffect(() => {
    let ids = map(fulfillmentItems, 'productCode');
    loadProductInventoryById(ids);

    if (props?.documentDetails) {
      setCurrentFromAddress(props?.documentDetails?.shipFrom);
      setCurrentToAddress(props?.documentDetails?.shipTo);
      setIsFromAddressUpdated(true);
      setIsToAddressUpdated(true);
    }
  }, []);

  useEffect(() => {
    setIsDropShip(props.isFulfillmentForDropship);
  }, [props.isFulfillmentForDropship]);

  const getPendingandRequiredQty = (fulfillmentItem: any) => {
    let qtyToFulfill;
    let pendingQty = Utility.getPendingQuantityForPopup(fulfillmentItem, true);
    let availableQuantity = Utility.getAvailableQuantity(fulfillmentItem);
    if (fulfillmentItem.type === PRODUCT_TYPE.NON_TRACKED) {
      qtyToFulfill = pendingQty;
    } else {
      qtyToFulfill =
        pendingQty > availableQuantity
          ? availableQuantity < 0
            ? pendingQty
            : availableQuantity
          : pendingQty;
    }
    return { pendingQty, qtyToFulfill };
  };

  const getRequiredQuantity = (item: any) => {
    const { pendingQty, qtyToFulfill } = getPendingandRequiredQty(item);
    return item.quantityRequired !== undefined && item.quantityRequired !== null
      ? item.quantityRequired
      : pendingQty;
  };

  const updateWarehouseInProductDetails = (warehousesList: any) => {
    setFulfillmentItems((prevItems: any) => {
      let copyItems = [...prevItems]; // Use the latest state
      copyItems.forEach((element: any) => {
        let requiredQuantityForElement = getRequiredQuantity(element);
        let warehouseWithProduct = warehousesList?.filter(
          (war: any) =>
            war?.productAvailableQuantity?.[element.productCode] >=
            requiredQuantityForElement
        );
        let foundWarehouse = warehouseWithProduct?.find(
          (warehouseItem: any) =>
            warehouseItem?.code === element?.product?.warehouseCode
        );
        if (!Utility.isEmpty(foundWarehouse)) {
          element.product.warehouseCode = element.product.warehouseCode;
          element.warehouseCode = element.product.warehouseCode;
        } else {
          let primaryWarehouse = warehouseWithProduct?.find(
            (warehouseItem: any) => warehouseItem?.primary
          );
          if (!Utility.isEmpty(primaryWarehouse)) {
            element.product.warehouseCode = primaryWarehouse.code;
            element.warehouseCode = primaryWarehouse.code;
          } else {
            element.product.warehouseCode = warehouseWithProduct?.[0]?.code;
            element.warehouseCode = warehouseWithProduct?.[0]?.code;
          }
        }
      });
      return copyItems; // Return the updated items
    });
  };

  useEffect(() => {
    let warehouses = warehouseProduct;
    if (!Utility.isEmpty(warehouses) && Utility.isEmpty(defaultWarehouse)) {
      let defaultWarehouseData =
        warehouses?.find((warehouse: any) => warehouse.primary) ||
        warehouses[0];

      defaultWarehouseData &&
        defaultWarehouseData.code &&
        updateInventory(defaultWarehouseData.code);
      setDefaultWarehouse(defaultWarehouseData);
    }
    if (!Utility.isEmpty(warehouses)) {
      updateWarehouseInProductDetails(warehouses);
    }
    setWarehouses(warehouses);
  }, [warehouseProduct, warehouses, defaultWarehouse]);

  useEffect(() => {
    checkIsPartialInvoice();
    checkIsPartialSalesOrder();
    /* if (tenantInfo.country === COUNTRY_CODES.IN) {
      setIsVisibleState(true);
    } */
    return () => {
      // null
    };
  }, [warehouseProduct]);

  const loadProductInventoryById = async (ids: any) => {
    try {
      showLoader('Fetching warehouse details...');
      // await dispatch(fetchProductInventoryByID(ids));
      await ProductService.fetchWarehouseProductsByID(ids)
        .then((res: any) => {
          setIsWarehouseAPILoaded(true);
          setWarehouseProduct(res?.warehouses);
          removeLoader();
        })
        .catch((error: any) => {
          setIsWarehouseAPILoaded(true);
          removeLoader();
        });
    } catch (err) {
      setIsWarehouseAPILoaded(true);
      removeLoader();
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT,
        data: () => {
          saveFulfillment();
        }
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: documentDetails
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  const fulfillmentPayload = (): FulfillmentPayload => {
    const updatedFulfillmentItems: any[] = fulfillmentItems.filter(
      (item: any) =>
        Utility.getPendingQuantityForPopup(item, true) > 0 &&
        item.fulfilledQuantity > 0
    );
    let linkedDocType = documentDetails?.linkedDocuments?.[0]?.documentType;
    let docItems: any[] = [];
    switch (linkedDocType) {
      case DOC_TYPE.QUOTE:
        docItems = quotationItems;
        break;
      case DOC_TYPE.SALES_ORDER:
        docItems = salesOrderItems;
        break;
    }

    let fulfillmentPayload = {
      ...documentDetails,
      fulfillmentDate: DateFormatService.getDateStrFromDate(
        fulfillmentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      fulfillmentType: props.isFulfillmentForDropship
        ? FULFILLMENT_TYPE.DROP_SHIP
        : FULFILLMENT_TYPE.DEFAULT,
      warehouseCode: warehouseCode,
      customerOrderNumber: documentDetails.customerOrderNumber,
      documentCode:
        documentDetails.salesInvoiceCode ||
        documentDetails.quotationCode ||
        documentDetails.salesOrderCode,
      documentType: docType,
      isPartialInvoice: isPartialInvoice,
      isPartialSalesOrder: isPartialSalesOrder,
      reservedStock: documentDetails.reservedStock,
      linkedPIDocument:
        documentDetails.linkedDocuments && !documentDetails.backOrder
          ? documentDetails.linkedDocuments.find((doc: any) => {
              return doc.documentType === DOC_TYPE.SALES_ORDER;
            })
          : null,

      fulfillmentItems: updatedFulfillmentItems.map((item: any) => {
        let documentItem;
        const documentItemList = docItems?.filter(
          (docItem) => docItem.productCode === item.productCode
        );

        if (documentItemList?.length > 1) {
          documentItem = docItems?.find(
            (docItem) =>
              docItem.productCode === item.productCode &&
              item.linkedQuoteItem === docItem.id
          );
        } else {
          documentItem = documentItemList?.[0];
        }

        return {
          ...item,
          advancedTracking: item.product.advancedTracking,
          uomQuantity: item.documentUOMSchemaDefinition
            ? item.uomPendingQuantity
            : item.pendingQuantity,
          linkedPIItemCode:
            documentItem && linkedDocType === DOC_TYPE.QUOTE
              ? documentItem.quotationItemCode
              : documentItem && linkedDocType === DOC_TYPE.SALES_ORDER
              ? documentItem.salesOrderItemCode
              : null,
          customField: getLineItemCFs(item)
        };
      })
    };

    if (Utility.isNotEmpty(currentFromAddress)) {
      fulfillmentPayload = {
        ...fulfillmentPayload,
        shipFrom: currentFromAddress
      };
    }
    if (Utility.isNotEmpty(currentToAddress)) {
      fulfillmentPayload = {
        ...fulfillmentPayload,
        shipTo: currentToAddress
      };
    }
    const payload = new FulfillmentPayload(fulfillmentPayload);
    return payload;
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = childColumnConfig;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(selectCustomFieldsData?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = selectCustomFieldsData?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'PRODUCT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const checkIfQCRequired = async () => {
    let productCodes = fulfillmentItems?.map((item: any) => item?.productCode);
    let response: any =
      await CommonQualityCheckService.getTemplateUsageByProduct(productCodes);
    let isQualityCheck = response?.reduce(
      (prev: boolean, current: any) => prev || current.used,
      false
    );
    return isQualityCheck;
  };

  const saveFulfillment = async () => {
    let hasError = false;
    let Payload = fulfillmentPayload();
    let hasAdvancedTracking: any[] = [];
    let hasRRBValidation: any = [];
    let hasPendingQtyError: boolean[] = [];
    let pendingQtyErrorMsg =
      'Commiting quantity should not be more than required';

    if (!Utility.isEmpty(Payload.fulfillmentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        Payload.fulfillmentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Fulfillment date',
          'FULFILLMENT'
        )
      ) {
        return;
      }
      if (!Utility.checkClosingDate(docDate, 'Fulfillment date')) {
        return;
      }
    }
    fulfillmentItems.forEach((items: any) => {
      if (items.invalidFields?.length > 0) {
        hasError = true;
      }
    });

    if (Payload.fulfillmentItems && Payload.fulfillmentItems.length === 0) {
      setDisplayError(true);
      setErrorMessageText(
        'At least one product must have more than 0 committing quantity'
      );
      return;
    } else {
      Payload.fulfillmentItems.forEach((element) => {
        if (
          element.advancedTracking === TRACKING_TYPE.BATCH ||
          element.advancedTracking === TRACKING_TYPE.SERIAL
        ) {
          const tracking =
            (element.advancedTrackingFulfilmentData &&
              element.advancedTrackingFulfilmentData.length > 0) ||
            element.isQuickCommit
              ? false
              : true;
          hasAdvancedTracking.push(tracking);
        } else {
          if (element.productType !== PRODUCT_TYPE.NON_TRACKED) {
            element.warehouseInventoryData?.forEach((inventory: any) => {
              const warehouse = warehouseProduct?.find(
                (w: any) => w.code === inventory.warehouseCode
              );
              if (
                !Utility.isEmpty(tenantInfo?.rowRackBinData) &&
                !Utility.isEmpty(
                  warehouse?.rowRackBinProductAvailableQuantityDtos
                ) &&
                Utility.isEmpty(inventory?.rowCode) &&
                Utility.isEmpty(inventory?.rackCode) &&
                Utility.isEmpty(inventory?.binCode)
              ) {
                hasRRBValidation.push(true);
                return;
              } else {
                hasRRBValidation.push(false);
              }
            });
          }
        }
        if (
          element.warehouseInventoryData &&
          element.warehouseInventoryData.length > 0
        ) {
          let tempFulfilledQty = 0;
          let tempPendingQty = 0;
          if (element?.documentUOMSchemaDefinition) {
            tempFulfilledQty = +element?.uomFulfilledQuantity;
            tempPendingQty = +element?.uomPendingQuantity;
          } else {
            tempFulfilledQty = +element.fulfilledQuantity;
            tempPendingQty = +element?.pendingQuantity;
          }
          if (tempFulfilledQty && tempFulfilledQty > 0) {
            let totalFulfilledQty = Utility.roundingOff(
              element.warehouseInventoryData.reduce(
                (previousValue, currentValue) =>
                  Number(previousValue) + Number(currentValue?.quantity || 0),
                0
              ),
              QTY_ROUNDOFF_PRECISION_BACKEND
            );
            if (element?.documentUOMSchemaDefinition) {
              totalFulfilledQty = Utility.getUomQuantity(
                totalFulfilledQty,
                element?.documentUOMSchemaDefinition
              );
            }
            if (
              Utility.roundOff(totalFulfilledQty, 2) !==
              Utility.roundOff(tempFulfilledQty, 2)
            ) {
              showAlert(
                'Error',
                'Committing Quantity should match the Quantity Allocated to the warehouse. Please reallocate the Committing Quantity to the warehouse'
              );
              hasError = true;
              return;
            }

            if (
              totalFulfilledQty > tempPendingQty &&
              !Utility.isSellsToleranceSettingsEnabled(docType, documentDetails)
            ) {
              const { isInvoiceLinked, isQuotationLinked, isReservedQuantity } =
                Utility.getSellsLinkedDocument(docType, documentDetails);
              if (isInvoiceLinked || isQuotationLinked) {
                pendingQtyErrorMsg = `Commiting quantity cannot be more than required quantity as the Invoice/${
                  Utility.isIndiaOrganisation() ? 'Quotation' : 'Estimates'
                } is already generated for this document.`;
              } else if (isReservedQuantity) {
                pendingQtyErrorMsg =
                  'Commiting quantity cannot be more than required quantity in case of reserved quantity.';
              }
              hasPendingQtyError.push(true);
            }
          }
        }
      });
      if (hasAdvancedTracking.includes(true)) {
        showAlert(
          'Error',
          `Serial/Batch Number must be assigned to ${localizedText(
            'do the fulfillment'
          )}.`
        );
        setDisplayError(true);
        setErrorMessageText(
          `Serial/Batch Number must be assigned to ${localizedText(
            'do the fulfillment'
          )}.`
        );
        return;
      }
      if (hasRRBValidation.includes(true)) {
        showAlert(
          'Warning!',
          'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
        );
        return;
      }
      if (hasPendingQtyError.includes(true)) {
        showAlert('Error', pendingQtyErrorMsg);
        return;
      }
    }

    // if (!tenantInfo.allowNegativeInventory) {
    let invalidItems: string[] = [];
    let invalidItemsProductCodes: any = [];
    let invalidItemsDocSeqCodes: any = [];
    let warehouseQtyList = deepClone(warehouseProduct);
    Payload.fulfillmentItems.forEach((record: any) => {
      let isNegativeInventory =
        tenantInfo.allowNegativeInventory &&
        record.productType === PRODUCT_TYPE.TRACKED;
      if (!isNegativeInventory) {
        if (
          record.warehouseInventoryData &&
          record.warehouseInventoryData?.length > 0
        ) {
          record.warehouseInventoryData.forEach((ele: any) => {
            warehouseQtyList.forEach((warehouse: any) => {
              if (
                warehouse.code === ele.warehouseCode &&
                warehouse?.productAvailableQuantity?.hasOwnProperty(
                  record.productCode
                )
              ) {
                if (
                  ele.quantity <=
                  warehouse.productAvailableQuantity[record.productCode]
                ) {
                  warehouse.productAvailableQuantity[record.productCode] =
                    Utility.roundingOff(
                      warehouse.productAvailableQuantity[record.productCode] -
                        ele.quantity,
                      QTY_ROUNDOFF_PRECISION_BACKEND
                    );
                } else {
                  if (!invalidItemsProductCodes.includes(record.productCode)) {
                    invalidItemsProductCodes.push(record.productCode);
                    invalidItems.push(record.productName);
                    invalidItemsDocSeqCodes.push(record.documentSequenceCode);
                  }
                }
              }
            });
          });
        } else if (
          record.advancedTracking &&
          record.advancedTrackingFulfilmentData?.length > 0
        ) {
          record.advancedTrackingFulfilmentData.forEach((ele: any) => {
            warehouseQtyList.forEach((warehouse: any) => {
              if (
                warehouse.code === ele.warehouseCode &&
                warehouse.productAvailableQuantity?.hasOwnProperty(
                  record.productCode
                )
              ) {
                if (
                  Number(ele.qtyToFulfil) <=
                  warehouse.productAvailableQuantity[record.productCode]
                ) {
                  warehouse.productAvailableQuantity[record.productCode] =
                    Utility.roundingOff(
                      warehouse.productAvailableQuantity[record.productCode] -
                        Number(ele.qtyToFulfil),
                      QTY_ROUNDOFF_PRECISION_BACKEND
                    );
                } else {
                  if (!invalidItemsProductCodes.includes(record.productCode)) {
                    invalidItemsProductCodes.push(record.productCode);
                    invalidItems.push(record.productName);
                    invalidItemsDocSeqCodes.push(record.documentSequenceCode);
                  }
                }
              }
            });
          });
        }
      }
    });
    if (invalidItems && invalidItems.length > 0) {
      setDisplayError(true);
      let invalidItemsStr = '';
      invalidItems?.forEach((invalidItem: string, index: number) => {
        if (invalidItems.length > 1 && index === invalidItems.length - 1) {
          invalidItemsStr += ` and ${invalidItem} (${invalidItemsDocSeqCodes[index]})`;
        } else {
          invalidItemsStr += `${invalidItem} (${invalidItemsDocSeqCodes[index]})`;
        }
      });
      setErrorMessageText(
        `${invalidItemsStr} ${
          invalidItems.length > 1 ? 'have' : 'has'
        } insufficient quantity.`
      );
      hasError = true;
      return;
    } else {
      if (!hasError) {
        hasError = false;
        setDisplayError(false);
      }
    }
    // }

    // Custom fields validation
    hasError = hasError || customFieldsContainsErrors(Payload.customField);

    if (!hasError) {
      showLoader();
      setDisplayError(false);
      setErrorMessageText('');
      let isQCCheckProductAvailable = await checkIfQCRequired();
      if (
        Utility.checkQCEnabledForModule(QC_FLOW_MODULES.FULFILLMENT) &&
        isQCCheckProductAvailable &&
        allowedDocumentsForFulfillmentQC.includes(docType) &&
        Utility.isMRP() &&
        !props.isFulfillmentForDropship
      ) {
        const isConfirmed = await qcConfirm(
          `Would you like to perform a quality check on this fulfillment?`,
          `QC Flow`
        );

        if (isConfirmed === null) {
          removeLoader();
          return;
        }

        if (isConfirmed) {
          Payload.isQcEnabled = true;
        }
      }
      if (props.isFulfillmentForDropship) {
        Payload.fulfillmentType = FULFILLMENT_TYPE.DROP_SHIP;
      }
      FulfillmentService.saveFulfillment(Payload)
        .then((res: any) => {
          closePopup(docType, Payload, hasAdvancedTracking.length, res);
        })
        .catch((error: any) => {
          setDisplayError(true);
          const errorMessage =
            error && error.data && error.data.errorMessage
              ? error.data.errorMessage
              : 'Unable to fulfill selected records';
          if (errorMessage.includes('exception')) {
            setErrorMessageText('Unable to save fulfillment, Please try again');
          } else {
            setErrorMessageText(errorMessage);
          }
          removeLoader();
        });
    }
  };

  const saveBackOrderFulfillment = () => {
    let documents = { ...documentDetails };
    let lineItems = Utility.getInsufficientInventoryItems(documents.items);
    documents.items = lineItems;
    let backOrderDetails = Utility.createBackOrder(documents);

    let payloadData = {
      title: LABELS.PURCHASE_ORDERS,
      type: LABELS.PURCHASE_ORDERS,
      tableId: draftsTableId,
      columnConfig: draftsColumnConfig,
      populateFormData: backOrderDetails,
      isCenterAlign: true,
      isMaximized: true
    };

    if (!Utility.isEmpty(backOrderDetails)) {
      dispatch(createBlankDraft({ payloadData, draftType: DraftTypes.NEW }));
      if (typeof props.closePopup !== 'undefined') {
        props.closePopup();
      }
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
    }
  };

  const closePopup = (
    docType: DOC_TYPE,
    payload: any,
    HasAdvancedTracking: any,
    successResp?: any
  ) => {
    const fulfillmentCode = successResp.fulfillment_code;
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.FULFILLMENT_SUCCESS
      });
    }
    if (docType === DOC_TYPE.INVOICE) {
      dispatch(fetchInvoices());
    } else if (docType === DOC_TYPE.SALES_ORDER) {
      dispatch(fetchSalesOrders());
    } else {
      dispatch(fetchQuotes());
    }
    if (HasAdvancedTracking > 0) {
      payload.fulfillmentItems.forEach((element: any) => {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      });
    }

    let fulfillmentSuccessMessage = `Fulfillment of ${fulfillmentCode} is saved successfully${
      payload.isQcEnabled ? `, please go through the quality check list.` : `.`
    }`;
    let popupHeader = 'Success!';

    if (successResp?.ffSentForToleranceApproval) {
      popupHeader = 'Tolerance Alert!';
      fulfillmentSuccessMessage = `Fulfillment of ${fulfillmentCode} has been sent for approval, it will be available for approval in some time. In the mean time, you can view remaining pending approval Fulfillment${
        payload.isQcEnabled
          ? `, ${fulfillmentCode} will be available for QC once approved.`
          : `.`
      }`;
    }

    let buttons = [
      {
        title: 'Ok',
        className: `${
          payload.isQcEnabled ? `bg-gray2` : `bg-blue text-white`
        } border-m`,
        onClick: () => {
          if (props?.closePopup) {
            props?.closePopup();
          }
        }
      }
    ];

    if (payload.isQcEnabled && !successResp?.ffSentForToleranceApproval) {
      buttons.push({
        title: 'Go to Quality Check',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          RouteManager.navigateToPage(PAGE_ROUTES.QUALITY_CHECK);
        }
      });
    }

    if (successResp?.ffSentForToleranceApproval) {
      if (
        checkUserPermission(PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL) ||
        checkUserPermission(PERMISSIONS_BY_MODULE.QUOTATION.FULFILL) ||
        checkUserPermission(PERMISSIONS_BY_MODULE.INVOICE.FULFILL)
      ) {
        buttons.push({
          title: 'Go to Approval screen',
          className: 'bg-blue text-white ml-r',
          onClick: () => {
            FulillmentMasterService.setSelectedIndex(1);
            RouteManager.navigateToPage(PAGE_ROUTES.FULFILLMENT_MASTER_LIST);
          }
        });
      }
    }

    showAlert(popupHeader, fulfillmentSuccessMessage, buttons);
    removeLoader();
    setDisplayError(false);
    setErrorMessageText('');
  };

  const updateInventory = (key: string) => {
    let filteredProduct = warehouseProduct.find(
      (warehouse: any) => warehouse.code === key
    );

    let backOrderItem: any = {};
    let newFulfillmentItem: any = [];
    fulfillmentItems.map((item, index) => {
      let sumOfAvailableQtyInAllWarehouse: number = warehouseProduct?.reduce(
        (prev: number, current: any) =>
          Utility.roundingOff(
            prev +
              Number(
                current?.productAvailableQuantity?.[item.productCode] || 0
              ),
            QTY_ROUNDOFF_PRECISION_BACKEND
          ),
        0
      );
      const pendingQuantity = Utility.getPendingQuantityForPopup(item);
      if (item.type === PRODUCT_TYPE.NON_TRACKED) {
        item.availableProductQuantity = 0;
      } else {
        item.availableProductQuantity =
          sumOfAvailableQtyInAllWarehouse ??
          item.product.availableQuantity ??
          0;
      }

      if (item.type === PRODUCT_TYPE.NON_TRACKED) {
        item.fulfilledQuantity = pendingQuantity;
      } else {
        if (pendingQuantity > item.availableProductQuantity) {
          let isNegativeInventory =
            tenantInfo.allowNegativeInventory &&
            item.type === PRODUCT_TYPE.TRACKED;
          if (isNegativeInventory) {
            item.fulfilledQuantity =
              item.availableProductQuantity > 0
                ? item.availableProductQuantity
                : pendingQuantity;
          } else {
            item.fulfilledQuantity = item.availableProductQuantity;
          }
        } else {
          item.fulfilledQuantity = pendingQuantity;
        }
      }

      if (isDropShip) {
        item.fulfilledQuantity = pendingQuantity;
      }

      item.pendingQuantity = Utility.roundingOff(
        pendingQuantity -
          item.qtyConvertedToDropShip + //  ZEN-13999 Partial Dropship Feature change.
          item.qtyFulfilledFromToDropShip +
          item.qtyFulfilledFromPps -
          item.qtyConvertedToPps,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      item.fulfilledQuantity = Utility.roundingOff(
        item.fulfilledQuantity -
          item.qtyConvertedToDropShip +
          item.qtyFulfilledFromPps + //  ZEN-13999 Partial Dropship Feature change.
          item.qtyFulfilledFromToDropShip -
          item.qtyConvertedToPps,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );

      if (props.isFulfillmentForDropship) {
        item.pendingQuantity =
          item.qtyConvertedToDropShip - item.qtyFulfilledFromToDropShip;
        item.fulfilledQuantity =
          item.qtyConvertedToDropShip - item.qtyFulfilledFromToDropShip;
      }

      if (item.documentUOMSchemaDefinition) {
        item.uomQuantity =
          item.uomQuantity ||
          Utility.getUomQuantity(
            item.productQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomUnitPrice =
          item.uomUnitPrice ||
          Utility.getUomPrice(item.unitPrice, item.documentUOMSchemaDefinition);
        item.uomAvailableQuantity =
          item.uomAvailableQuantity ||
          Utility.getUomQuantity(
            item.availableQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomFulfilledQuantity =
          item.uomFulfilledQuantity ||
          Utility.getUomQuantity(
            item.fulfilledQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomQuantityFulfilled =
          item.uomQuantityFulfilled ||
          Utility.getUomQuantity(
            item.quantityFulfilled,
            item.documentUOMSchemaDefinition
          );
        item.uomPendingQuantity =
          item.uomPendingQuantity ||
          Utility.getUomQuantity(
            item.pendingQuantity,
            item.documentUOMSchemaDefinition
          );
      }
      item.pendingQuantity = Utility.roundingOff(
        item.pendingQuantity,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      item.fulfilledQuantity = Utility.roundingOff(
        item.fulfilledQuantity,
        QTY_ROUNDOFF_PRECISION_BACKEND
      );
      // item.warehouseCode = getWarehouseForItem(item);

      if (
        documentDetails.isPartialInvoice ||
        documentDetails.hasPartialInvoice
      ) {
        item.quantityToBeFulfilled = Utility.roundingOff(
          item.pendingQuantity || 0,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
        item.uomQuantityToBeFulfilled = Utility.roundingOff(
          item.uomPendingQuantity || 0,
          QTY_ROUNDOFF_PRECISION_BACKEND
        );
      }

      if (
        item.type === PRODUCT_TYPE.TRACKED &&
        item.availableQuantity < pendingQuantity
      ) {
        backOrderItem[item.productCode] =
          pendingQuantity - item.availableQuantity;
      }
      newFulfillmentItem.push(item);
    });
    if (Object.keys(backOrderItem).length > 0) {
      setBackOrderNeeded(true);
      // setBackOrderItems(backOrderItem);
    }
    setCompleteProcess(true);
    setWarehousecode(filteredProduct?.code);
    setFulfillmentItems(newFulfillmentItem);
  };

  const onItemUpdated = (data: FulfillmentItemState) => {
    let fulfillmentItems =
      documentDetails.salesInvoiceItems ||
      documentDetails.quotationItemDtoList ||
      documentDetails.salesOrderItems;
    let fulfillmentItemsKey =
      docType === DOC_TYPE.INVOICE
        ? 'salesInvoiceItems'
        : docType === DOC_TYPE.SALES_ORDER
        ? 'salesOrderItems'
        : 'quotationItemDtoList';
    if (fulfillmentItems) {
      const indexToUpdate = fulfillmentItems.findIndex(
        (item: any) => item.id === data.id
      );
      let itemsArr = [...fulfillmentItems];
      itemsArr[indexToUpdate] = data;
      setFulfillmentItems(itemsArr);
      setAllAssignedData(itemsArr);
      setDocumentDetails({
        ...documentDetails,
        [fulfillmentItemsKey]: itemsArr
      });
    }
  };
  const checkIsLocalizedUomQty = () => {
    return fulfillmentItems?.some((item: any) => item.isLocalizedUomQty);
  };
  const showBackorderCreate = () => {
    if (
      !isDropShip &&
      !isBackOrder &&
      backOrderNeeded &&
      !checkIsLocalizedUomQty() &&
      checkUserPermission(PERMISSIONS_BY_MODULE.PURCHASE_ORDER.CREATE)
    ) {
      return (
        <div className="row rounded bg-yellow-100 p-s mt-m mb-s">
          Some products have insufficient quantity. Please create&nbsp;
          <span
            className="font-bold cursor-hand underline hover:color-blue-500"
            onClick={() => saveBackOrderFulfillment()}
          >
            Backorder
          </span>
          .
        </div>
      );
    }
  };
  const showErrorMessage = () => {
    if (displayError) {
      return (
        <div className="row rounded bg-red-100 p-2 parent-width mt-m mb-s">
          <span>{errorMessageText}</span>
        </div>
      );
    }
  };

  const checkIsPartialInvoice = () => {
    const partialInvoiceFlag =
      documentDetails.isPartialInvoice ||
      documentDetails.hasPartialInvoice ||
      false;
    setIsPartialInvoice(partialInvoiceFlag);
    if (
      partialInvoiceFlag &&
      documentDetails.documentType === DOC_TYPE.INVOICE
    ) {
      if (
        documentDetails?.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE
      ) {
        getQuotationItemCodes();
      } else if (
        documentDetails?.linkedDocuments?.[0]?.documentType ===
        DOC_TYPE.SALES_ORDER
      ) {
        getSalesOrderItemCodes();
      }
    } else {
      setIsLinkedDocAPILoaded(true);
    }
  };

  const checkIsPartialSalesOrder = () => {
    const partialSalesOrderFlag =
      documentDetails.isPartialSalesOrder ||
      documentDetails.hasPartialSalesOrder ||
      false;
    setIsPartialSalesOrder(partialSalesOrderFlag);
    if (
      partialSalesOrderFlag &&
      documentDetails.documentType === DOC_TYPE.SALES_ORDER
    ) {
      setSalesOrderItems(documentDetails['salesOrderItems'] || []);
    } else {
      setIsLinkedDocAPILoaded(true);
    }
  };

  const getSalesOrderItemCodes = () => {
    if (
      documentDetails.linkedDocuments &&
      documentDetails.linkedDocuments.length > 0
    ) {
      const salesOrderDocumentCode =
        documentDetails.linkedDocuments?.[0].documentCode;
      showLoader('Fetching details...');
      SalesOrderService.getSalesOrderByCode(salesOrderDocumentCode)
        .then((data: any) => {
          setSalesOrderItems(data['salesOrderItems']);
          setIsLinkedDocAPILoaded(true);
          // removeLoader();
        })
        .catch((err: any) => {
          setIsLinkedDocAPILoaded(true);
          const buttons = [
            {
              title: 'OK',
              className: 'bg-blue text-white border-m mr-r',
              onClick: () => {
                if (props.closePopup) {
                  props.closePopup();
                }
              }
            }
          ];
          showAlert(
            'Error',
            ' Error while fetching linked documents, Please try again later',
            buttons
          );
          // removeLoader();
          console.error('Error loading linked SO: ', err);
        });
    }
  };

  const getQuotationItemCodes = () => {
    if (
      documentDetails.linkedDocuments &&
      documentDetails.linkedDocuments.length > 0
    ) {
      const quotationDocumentCode =
        documentDetails.linkedDocuments?.[0].documentCode;
      showLoader('Fetching details...');
      QuotationService.getQuoteByCode(quotationDocumentCode)
        .then((data: any) => {
          setQuotationItems(data['quotationItemDtoList']);
          setIsLinkedDocAPILoaded(true);
          // removeLoader();
        })
        .catch((err: any) => {
          setIsLinkedDocAPILoaded(true);
          const buttons = [
            {
              title: 'OK',
              className: 'bg-blue text-white border-m mr-r',
              onClick: () => {
                if (props.closePopup) {
                  props.closePopup();
                }
              }
            }
          ];
          showAlert(
            'Error',
            ' Error while fetching linked documents, Please try again later',
            buttons
          );
          // removeLoader();
          console.error('Error loading linked SO: ', err);
        });
    }
  };

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 20 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const validateAndUpdateDate = (
    newDate: Date,
    minAcceptedDate: Date,
    callback: any,
    warningMessage: string
  ) => {
    if (newDate.getTime() >= minAcceptedDate.getTime()) {
      if (!Utility.checkClosingDate(newDate, 'Fulfillment date')) {
        return;
      }
      if (
        !Utility.checkActiveDateRangeValidation(
          newDate,
          tenantInfo,
          'Fulfillment date',
          'FULFILLMENT'
        )
      ) {
        return;
      }
      callback(newDate);
    } else {
      showAlert('Invalid Date', getCapitalized(warningMessage.toLowerCase()));
    }
  };

  const getBuildAssemblyPopup = () => {
    return (
      <BuildAssembly
        product={assemblyProduct}
        onClose={() => {
          setShowBuildAssemblyPopup(false);
        }}
        onBackOrderOpen={() => {
          if (props.closePopup) {
            props.closePopup();
          }
        }}
        onSave={(data: any) => {
          setShowBuildAssemblyPopup(false);
          let ids = map(fulfillmentItems, 'productCode');
          loadProductInventoryById(ids);
        }}
      />
    );
  };

  const onChangeAssemblyProduct = (data: any) => {
    setAssemblyProduct(data);
    setShowBuildAssemblyPopup(true);
  };

  const getWarehouseForItem = (data: any) => {
    if (
      Utility.isEmpty(documentDetails.linkedDocuments) &&
      isWarehouseAPILoaded
    ) {
      removeLoader();
    } else if (isDropShip && isWarehouseAPILoaded) {
      removeLoader();
    } else if (isWarehouseAPILoaded && isLinkedDocAPILoaded) {
      removeLoader();
    } else if (isBackOrder && isWarehouseAPILoaded) {
      removeLoader();
    }
    if (!Utility.isEmpty(warehouses)) {
      let itemWarehouse = warehouses.filter(
        (warehouse: any) =>
          warehouse['productAvailableQuantity']?.[data.productCode]
      );
      let primaryWH: any = [];
      if (!itemWarehouse || itemWarehouse.length > 1) {
        primaryWH = warehouses.filter((warehouse) => warehouse.primary);
      } else {
        primaryWH = itemWarehouse;
      }
      if (primaryWH.length === 0) primaryWH[0] = warehouses[0];
      return primaryWH[0].code;
    } else {
      return defaultWarehouse.code;
    }
  };

  const showCustomFieldView = () => {
    return (
      <div className="column parent-width mt-l" style={{ minHeight: 57 }}>
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={module}
          customFieldsList={props.documentDetails.customField}
          columnConfig={colConfig}
          columnConfigTableId={configTableId ? configTableId : ''}
          onUpdate={(list: any) => {
            const updatedDetails = documentDetails;
            updatedDetails.customField = list;
            setDocumentDetails({ ...updatedDetails });
          }}
        />
      </div>
    );
  };

  const showAttachmentView = () => {
    return (
      <Fragment>
        <DKButton
          title={
            <>
              + Attach files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          className={`text-blue mt-r fw-m`}
          style={{ paddingLeft: 0, maxWidth: 150 }}
          // disabled={props.draftType === DraftTypes.READONLY}
          onClick={triggerAttachmentUpload}
        />
        <div className="row">{getAttachments()}</div>
      </Fragment>
    );
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap mt-r mb-r">
        {attachments.map((attachment: any) => (
          <div
            className={`row width-auto border-m border-radius-s p-h-s p-v-s mr-r bg-gray0 ${
              isRemovingAttachment ? 'pointer-events-none' : ''
            }`}
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-xs-2 cursor-pointer mr-xs opacity-50 hover:opacity-60"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="cursor-pointer border-none"
              title={attachment.attachmentFileName}
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              <DKLabel
                text={attachment.attachmentFileName}
                style={{
                  maxWidth: 150,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              />
            </div>

            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-xs-2 ml-s cursor-pointer opacity-50 hover:opacity-60"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {
        showToast('Something went wrong, while downloading the attachment.');
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true')
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const attachmentForListing = [...attachments, ...resList];
          const newlyAddedAttachments = [...newAttachments, ...resList];
          setAttachments(attachmentForListing);
          setNewAttachments(newlyAddedAttachments);
          setDocumentDetails((prevState: any) => {
            return {
              ...prevState,
              attachmentIds: newlyAddedAttachments.map(
                (attachment: any) => attachment.attachmentId
              ),
              attachments: newlyAddedAttachments.map((attachment: any) =>
                JSON.stringify(attachment.attachmentId)
              )
            };
          });
        }).catch((err) => {
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = async (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP['FULFILLMENT'];
    // const entityId = booksDocument.id || booksDocument.entityId || '';
    const entityId = '';
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: ''
    };

    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );

      return;
    }

    return AttachmentService.uploadAttachment(file);
  };

  const removeAttachment = (attachmentId: any) => {
    setIsRemovingAttachment(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const attachmentForListing = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        const newlyAddedAttachments = newAttachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );

        setAttachments(attachmentForListing);
        setNewAttachments(newlyAddedAttachments);
        setDocumentDetails((prevState: any) => {
          return {
            ...prevState,
            attachmentIds: newlyAddedAttachments.map(
              (attachment: any) => attachment.attachmentId
            ),
            attachments: newlyAddedAttachments.map((attachment: any) =>
              JSON.stringify(attachment.attachmentId)
            )
          };
        });
        setIsRemovingAttachment(false);
      })
      .catch(() => {
        showToast(
          'Something went wrong while removing the attachment, please try again.'
        );
        setIsRemovingAttachment(false);
      });
  };

  const setAllAssignedData = (items: any) => {
    let similarProductsAdvancedTrackingData: any = [];
    for (let i = 0; i < items?.length; i++) {
      let currentItem = items[i];
      similarProductsAdvancedTrackingData = [
        ...(similarProductsAdvancedTrackingData || []),
        ...(currentItem?.advancedTrackingFulfilmentData?.map(
          (lineItem: any) => {
            return {
              ...lineItem,
              lineNumber: currentItem.lineNumber,
              productCode: currentItem.productCode
            };
          }
        ) || [])
      ];
    }
    setAssignedAdvancedData(similarProductsAdvancedTrackingData);
  };

  const getColumnConfigFromDocType = () => {
    let columnConfigInfo: { tableId?: any; columnConfig?: any } = {};

    switch (documentDetails.documentType) {
      case DOC_TYPE.INVOICE:
        columnConfigInfo = {
          columnConfig: invoiceColumnConfig,
          tableId: invoiceDocConfigTableId
        };
        break;
      case DOC_TYPE.QUOTE:
        columnConfigInfo = {
          columnConfig: quoteColumnConfig,
          tableId: quoteConfigTableId
        };
        break;
      case DOC_TYPE.SALES_ORDER:
        columnConfigInfo = {
          columnConfig: salesOrderColumnConfig,
          tableId: salesOrderConfigTableId
        };
        break;
      default:
        break;
    }

    return columnConfigInfo;
  };

  const getAddressCustomFields = (addressType: any) => {
    let addressCustomFields = [];
    if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
      addressCustomFields = currentFromAddress?.customFields ?? [];
    } else if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
      addressCustomFields = currentToAddress?.customFields ?? [];
    }

    return Utility.isNotEmpty(addressCustomFields) ? (
      <div className={`${addressCustomFields?.length ? 'mb-r mr-l' : ''}`}>
        <CustomFieldsHolder
          moduleName={MODULES_NAME.CONTACT_ADDRESS}
          customFieldsList={addressCustomFields ? addressCustomFields : []}
          columnConfig={getColumnConfigFromDocType().columnConfig}
          columnConfigTableId={getColumnConfigFromDocType().tableId}
          documentMode={DOCUMENT_MODE.NEW}
          onUpdate={async (updatedCFList) => {
            if (addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM) {
              let currentFromAddressCopy: any = {
                ...currentFromAddress,
                customFields: [...updatedCFList]
              };
              setCurrentFromAddress(currentFromAddressCopy);
            }
            if (addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS) {
              let currentToAddressCopy: any = {
                ...currentToAddress,
                customFields: [...updatedCFList]
              };
              setCurrentToAddress(currentToAddressCopy);
            }
          }}
          onLocationUpdate={(loc) => {}}
          contact={documentDetails?.contact}
          hideAddfieldButton={true}
          addressUpdate={isFromAddressUpdated || isToAddressUpdated}
          updateAddressField={() => {
            setIsFromAddressUpdated(false);
            setIsToAddressUpdated(false);
          }}
        />
      </div>
    ) : null;
  };

  const getContactCard = (title: string, addressType: BOOKS_ADDRESS_TYPES) => {
    return (
      <div
        className={`column document-address-block`}
        style={{
          minWidth: 420,
          maxWidth: 420,
          marginLeft: -10,
          paddingRight: 10,
          paddingLeft: 10,
          paddingBottom: 10
        }}
      >
        <div className="row parent-block justify-content-between">
          <div
            className={`row width-auto cursor-pointer listPickerBG`}
            onClick={() => {}}
          >
            <DKLabel text={title} className="fw-b fs-r text-gray" />
          </div>
        </div>
        <div className={`mt-s`}>
          {/* From */}
          {addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM && (
            <div className="position-relative">
              <div className={`row cursor-pointer listPickerBG`}>
                <DKLabel text={''} className="fw-m fs-r" />
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {
                  setOpenAddressPicker(BOOKS_ADDRESS_TYPES.SHIP_FROM);
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(currentFromAddress, false)
                ) && (
                  <DKLabel
                    text={getFormattedAddress(currentFromAddress, false)}
                  />
                )}
              </div>
              {openAddressPicker === BOOKS_ADDRESS_TYPES.SHIP_FROM && (
                <DKListPicker2
                  data={tenantInfo?.shippingAddresses ?? []}
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={null}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setOpenAddressPicker(null);
                    setIsFromAddressUpdated(true);
                    setCurrentFromAddress(addressObj);
                  }}
                  onClose={() => {
                    setOpenAddressPicker(null);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIP_FROM &&
            getAddressCustomFields(addressType)}

          {/* To */}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
            <div className="position-relative">
              <div className={`row cursor-pointer listPickerBG`}>
                <DKLabel text={''} className="fw-m fs-r" />
              </div>
              <div
                className={`row cursor-pointer listPickerBG`}
                onClick={() => {
                  setOpenAddressPicker(BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS);
                }}
              >
                {!Utility.isEmpty(
                  getFormattedAddress(currentToAddress, false)
                ) && (
                  <DKLabel
                    text={getFormattedAddress(currentToAddress, false)}
                  />
                )}
              </div>
              {openAddressPicker === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS && (
                <DKListPicker2
                  data={documentDetails?.contactDto?.shippingAddress ?? []}
                  className="position-absolute z-index-3 bg-white border-m shadow-m"
                  style={{ minWidth: 250 }}
                  renderer={(index: number, addressObj: any) => (
                    <div
                      style={{
                        width: 200,
                        whiteSpace: 'pre-wrap',
                        textAlign: 'left'
                      }}
                      dangerouslySetInnerHTML={{
                        __html: getFormattedAddress(addressObj)
                      }}
                    >
                      {/* {getFormattedAddress(addressObj)} */}
                    </div>
                  )}
                  onEdit={(index: number, obj: any) => {}}
                  button={null}
                  canEdit={false}
                  onSelect={(index: number, addressObj: any) => {
                    setOpenAddressPicker(null);
                    setIsToAddressUpdated(true);
                    setCurrentToAddress(addressObj);
                  }}
                  onClose={() => {
                    setOpenAddressPicker(null);
                  }}
                  allowSearch={false}
                />
              )}
            </div>
          )}
          {addressType === BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS &&
            getAddressCustomFields(addressType)}
        </div>
      </div>
    );
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {tenantInfo.country === COUNTRY_CODES.IL && (
          <>
            <div className="row">
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Payer Id"
                  value={documentDetails?.contactDto?.taxPayerIdIsrael || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word',
                  marginLeft: '10px'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Registration Number"
                  value={documentDetails?.contactDto?.taxNumber || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className="p-4">
      <div className="flex justify-content-between">
        <div className="row align-items-start">
          {getContactCard('Ship From', BOOKS_ADDRESS_TYPES.SHIP_FROM)}
          {getContactCard('Ship To', BOOKS_ADDRESS_TYPES.SHIPPING_ADDRESS)}
          <DKLine className="mt-s" />
        </div>

        <div className="flex flex-col w-48">
          <span className="text-black fs-r ml-0 font-medium"> Date</span>
          <div className="position-relative pt-2">
            <div
              className="text-align-left cursor-pointer font-medium"
              onClick={() => {
                setFulfillmentDateOpen(!fulfillmentDateOpen);
              }}
            >
              <div className="flex flex-row justify-between bg-gray-100 p-2 rounded">
                <span className="mr-1 w-24">
                  {DateFormatService.getDateStrFromDate(
                    fulfillmentDate,
                    DateFormatService.tenantDateFormat
                  )}
                </span>
                <span className="mt-0.5">
                  <img
                    src={ic_calender}
                    alt="calender"
                    className="h-4 object-cover"
                  />
                </span>
              </div>
            </div>
            {fulfillmentDateOpen &&
              getCalendarView(
                fulfillmentDate,
                (newDate: any) =>
                  validateAndUpdateDate(
                    newDate,
                    DateFormatService.getDateFromStr(
                      tenantInfo.bookBeginningStartDate,
                      BOOKS_DATE_FORMAT['YYYY-MM-DD']
                    ),
                    setFulfillmentDate,
                    'Fulfillment date cannot be before the book beginning date: ' +
                      tenantInfo.bookBeginningStartDate
                  ),
                setFulfillmentDateOpen
              )}
          </div>
        </div>
      </div>
      {tenantInfo.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
      {props.documentDetails.customField &&
        props.documentDetails.customField.length > 0 &&
        showCustomFieldView()}

      {showBackorderCreate()}
      {showBuildAssemblyPopup && <>{getBuildAssemblyPopup()}</>}
      {showErrorMessage()}
      {warehouseProduct &&
        warehouseProduct.length &&
        fulfillmentItems &&
        fulfillmentItems.map((item: any, index: number) => {
          item['warehouseCode'] =
            item.warehouseCode || getWarehouseForItem(item);
          const partialInvoiceFlag =
            documentDetails.isPartialInvoice ||
            documentDetails.hasPartialInvoice ||
            false;
          const pendingQuantity = Utility.getPendingQuantityForPopup(
            item,
            true
          );
          if (pendingQuantity <= 0) {
            return;
          }
        })}
      <FulfillmentGrid
        allotedAdvancedTrackingData={assignedAdvancedData}
        warehouseProduct={warehouseProduct}
        fulfillmentItems={fulfillmentItems}
        isPartialInvoice={isPartialInvoice}
        documentDetails={documentDetails}
        completeProcess={completeProcess}
        isDropShip={props.isFulfillmentForDropship}
        module={DOC_TYPE.FULFILLMENT}
        updateFulfillmentData={(data: any, childConfig: any) => {
          setFulfillmentItems(data);
          setChildColumnConfig(childConfig);
          let fulfillmentItemsKey =
            docType === DOC_TYPE.INVOICE
              ? 'salesInvoiceItems'
              : docType === DOC_TYPE.SALES_ORDER
              ? 'salesOrderItems'
              : 'quotationItemDtoList';

          setFulfillmentItems(data);
          setAllAssignedData(data);
          setDocumentDetails({
            ...documentDetails,
            [fulfillmentItemsKey]: data
          });
        }}
        localWarehousesForGrid={warehouseProduct}
        onFulfillmentItemUpdated={(data: FulfillmentItemState) => {
          onItemUpdated(data);
        }}
        onChangeAssemblyProduct={(data: any) => {
          onChangeAssemblyProduct(data);
        }}
        parentDocumentType={props.documentType ?? ''}
        parentDocumentDetails={props.documentDetails}
      />
      <div className="pb-4">{showAttachmentView()}</div>
    </div>
  );
};

export default Fulfillment;
