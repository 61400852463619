import { DateFormat, TableColumnPopupType } from "../../../Constants/Constants";
import CustomFieldManager from "../../../Manager/CustomFieldManager";
import TemplateSettingsManager from "../../../Manager/TemplateSettingsManager";
import { getLocalisedText } from "../../../Translate/LanguageManager";
import Utility, { getDateFrom, getDateString } from "../../../Utilities/Utility";
import { getSortedHeader } from "../TableUtility";

export class PackingListTableItem {
    lineNumber: number;
    id: number;
    code: string;
    name: string;
    barcode: string;
    unit: string;
    serialBatch: string;
    packedQuantity: string;
    serialNumber: string;
    batchNumber: string;
    batchQuantity: string;
    manufacturingDate: string;
    expiryDate: string;

    rawItemValue: any;
    data: any;
    constructor(packingListItem: any, data:any, currency: string) {
        this.lineNumber = packingListItem.lineNumber
        this.id = packingListItem.id
        this.code = packingListItem.code ? packingListItem.code : ''
        this.name = this.getProductName(data, packingListItem)
        this.barcode = packingListItem.barcode
        this.unit = packingListItem.unit ? packingListItem.unit : ''
        this.serialBatch = packingListItem.serialBatch
        this.packedQuantity = packingListItem.packedQuantity !== undefined ? packingListItem.packedQuantity.toString() : '0'
        
        this.serialBatch = packingListItem.serialBatch
        this.serialNumber = packingListItem.serialNumber ? packingListItem.serialNumber : ''
        this.batchNumber = packingListItem.batchNumber ? packingListItem.batchNumber : ''
        this.batchQuantity = packingListItem.batchQuantity ? packingListItem.batchQuantity : ''
        this.manufacturingDate = packingListItem.manufacturingDate ? packingListItem.manufacturingDate : ''
        this.expiryDate = packingListItem.expiryDate ? packingListItem.expiryDate : ''

        this.rawItemValue = packingListItem
        this.data = data
    }

    getProductName(data: any, lineItem: any): string {
        if (data.country === 'SA') {
            if (lineItem.nameInArabic !== undefined && lineItem.nameInArabic !== null) {
                if (lineItem.nameInArabic !== '') {
                    return getLocalisedText(lineItem.name) + '\n' + getLocalisedText(lineItem.nameInArabic)
                }
            }
        }
        return getLocalisedText(lineItem.name)
    }

    getBatchQty(lineItem: any): string {
        return isNaN(parseFloat(lineItem.batchQuantity)) ? '' : Utility.toDecimalFormat(parseFloat(lineItem.batchQuantity), TemplateSettingsManager.getQuantityDecimalScale())
    }

    getProductCustomFieldByCode(code: string) {
        var customFields = this.rawItemValue.customFields
        if (customFields === undefined || customFields === null) {
            return undefined
        }

        if (customFields.length === 0) {
            return undefined
        }

        return customFields.find((x: any) => x.code === code)
    }

    getCombineProductCustomField() {
        var val = ''
        var productTableConfiguration = TemplateSettingsManager.defaultTemplateSettings.productTableConfiguration
        if (productTableConfiguration !== undefined) {
            if (productTableConfiguration.isCombinesProductCustomField) {
                var productCustomFields = CustomFieldManager.getActiveProductCustomFields()
                if (productCustomFields !== undefined) {
                    if (productCustomFields.length > 0) {
                        productCustomFields.forEach(element => {
                            if (element.isSelected && element.code !== undefined) {
                                var field = this.getProductCustomFieldByCode(element.code)
                                if (field !== undefined) {
                                    var cfType = element.cfType;
                                    if (cfType === undefined) {
                                        var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                        if (customField && customField.fieldType) {
                                            cfType = customField.fieldType;
                                        }
                                    }

                                    if (cfType === "DATE") {
                                        val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                    } else {
                                        val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                    }
                                }
                            }
                        });
                    }
                    else {
                        if (productTableConfiguration.combinedProductCustomField !== undefined
                            && productTableConfiguration.combinedProductCustomField !== null) {
                            if (productTableConfiguration.combinedProductCustomField.length > 0) {
                                productTableConfiguration.combinedProductCustomField.forEach((element: any) => {
                                    if (element.isSelected && element.code !== undefined) {
                                        var field = this.getProductCustomFieldByCode(element.code)
                                        if (field !== undefined) {
                                            var cfType = element.cfType;
                                            if (cfType === undefined) {
                                                var customField = CustomFieldManager.getProductCustomFields().find((cf) => cf.code === element.code);
                                                if (customField && customField.fieldType) {
                                                    cfType = customField.fieldType;
                                                }
                                            }

                                            if (cfType === "DATE") {
                                                val += element.label + ': <b>' + (getDateString(getDateFrom(field.value, DateFormat.MM_DD_YYYY_SLASH), CustomFieldManager.getDateFormat()) || field.value) + '</b>\n'
                                            } else {
                                                val += element.label + ': <b>' + (field.value ?? '') + '</b>\n'
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            }
        }

        return val
    }


    toString() {
        var sortedHeader = getSortedHeader()
        if (sortedHeader.length > 0) {
            var sortedValue: string[] = []
            sortedHeader.forEach(element => {
                sortedValue.push(this.getValueByType(element))
            });
            return sortedValue
        }
        return this.getDefaultList()
    }

    getValueByType(element: any) {
        const type = element.type
        switch (type) {
            case TableColumnPopupType.id:
                return this.id
            case TableColumnPopupType.code:
                return getLocalisedText(this.code)
            case TableColumnPopupType.productName:
                return getLocalisedText(this.name)
            case TableColumnPopupType.barcode:
                return this.barcode
            case TableColumnPopupType.unit:
                return this.unit
            case TableColumnPopupType.serialBatch:
                return this.serialBatch ? this.serialBatch : ''
            case TableColumnPopupType.packedQuantity:
                return this.packedQuantity !== undefined ? this.packedQuantity : ''
            case TableColumnPopupType.serialNumber:
                return this.serialNumber ? this.serialNumber : ''
            case TableColumnPopupType.batchNumber:
                return this.batchNumber ? this.batchNumber : ''
            case TableColumnPopupType.batchQuantity:
                return this.batchQuantity ? this.batchQuantity : ''
            case TableColumnPopupType.manufacturingDate:
                return this.manufacturingDate ? this.manufacturingDate : ''
            case TableColumnPopupType.expiryDate:
                return this.expiryDate ? this.expiryDate : ''
            case TableColumnPopupType.serialBatchCustomFields:
                return CustomFieldManager.getCombineSerialBatchCustomField(this.rawItemValue, this.data);
            default:
                return ''
        }
    }

    getDefaultList() {
        return [
            this.id,
            getLocalisedText(this.code),
            getLocalisedText(this.name),
            this.barcode,
            this.unit,
            this.serialBatch,
            this.packedQuantity,
        ]
    }
}
