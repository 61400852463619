import {
  DKIcon,
  showLoader,
  showAlert,
  DKSpinner,
  removeLoader
} from 'deskera-ui-library';
import {
  DOC_TYPE,
  FULFILLMENT_TYPE,
  LABELS,
  POPUP_CALLBACKS_TYPE,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { useEffect, useState } from 'react';
import {
  FulfillmentPopupProps,
  FulfillmentState
} from '../../Models/Fulfillment';
import ic_fulfill_direct from '../../Assets/Icons/ic_fulfill_direct.svg';
import ic_fulfill_dropship from '../../Assets/Icons/ic_fulfill_dropship.svg';
import ic_fulfill_pps from '../../Assets/Icons/ic_fulfill_pps.svg';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import Utility, { getCapitalized } from '../../Utility/Utility';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  createBlankDraft,
  draftTableId,
  removeDraft,
  selectDraftsColumnConfig
} from '../../Redux/Slices/DraftsSlice';
import { DraftTypes } from '../../Models/Drafts';
import QuotationService from '../../Services/Quotation';
import { getUpdatedQuoteObject } from '../../Components/Quotations/QuoteHelper';
import InvoiceService from '../../Services/Invoice';
import { getUpdatedInvoiceObject } from '../../Components/Invoices/InvoiceHelper';
import { getUpdatedSalesOrderObject } from '../../Components/SalesOrders/SalesOrderHelper';
import SalesOrderService from '../../Services/SalesOrder';
import { localizedText } from '../../Services/Localization/Localization';
import {
  activeTenantInfo,
  appCustomizationInfo
} from '../../Redux/Slices/AuthSlice';
import { isShipToAddressValidForUSOrgs } from '../DocumentForm/NewDocumentHelper';
import { checkUserPermission } from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import PickPackShipService from '../../Services/PickPackShip';

const FulfillmentConfirmation: React.FC<FulfillmentPopupProps> = (props) => {
  const [docType, setDocType] = useState(props.documentType);
  const [documentDetails, setDocumentDetails] = useState<any>();
  const [document, setDocument] = useState(props.documentDetails);
  const [type, setType] = useState<any>('');
  const [fulfillmentType, setFulfillmentType] = useState(props.fulfillmentType);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [fulfillmentItems, setFulfillmentItems] = useState<
    FulfillmentState['fulfillmentItems']
  >(
    document.salesInvoiceItems ||
      document.quotationItemDtoList ||
      document.salesOrderItems
  );
  const [disabledDropship, setDisabledDropShip] = useState(false);
  const [disabledPPS, setDisabledPPS] = useState(false);
  const [disabledFulfillment, setDisabledFulfillment] =
    useState<boolean>(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const dispatch = useAppDispatch();
  const draftsTableId = useAppSelector(draftTableId);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const appCustomizationData = useAppSelector(appCustomizationInfo);

  useEffect(() => {
    const advancedTracking = fulfillmentItems?.filter(
      (item: any) => item?.product?.advancedTracking !== 'NONE'
    );
    if (advancedTracking && advancedTracking.length > 0) {
      setDisabledDropShip(true);
      // Enabling Adanced Tracking Products for PPS
      // setDisabledPPS(true);
    }
    if (appCustomizationData && appCustomizationData.hiddenApps) {
      let str = appCustomizationData.hiddenApps;
      let str_array = str.split(',');
      if (str_array.includes('PPS')) {
        setDisabledPPS(true);
      }
    }

    if (fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
      setDisabledFulfillment(true);
      //setDisabledDropShip(true);
      //setDisabledPPS(true);
    }
    if (fulfillmentType === FULFILLMENT_TYPE.DEFAULT) {
      setDisabledPPS(true);
    }
    // if (document.hasPartialInvoice || document.reservedStock) {
    //   setDisabledPPS(true);
    // }

    const bomProducts = fulfillmentItems?.filter(
      (item: any) => item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS
    );
    if (bomProducts && bomProducts.length > 0) {
      setDisabledDropShip(true);
    }
    const nonTrackedProducts = fulfillmentItems?.filter(
      (item: any) => item?.product?.type === PRODUCT_TYPE.NON_TRACKED
    );
    if (nonTrackedProducts && nonTrackedProducts.length > 0) {
      if (docType !== DOC_TYPE.SALES_ORDER) {
        setDisabledPPS(true);
      }
    }
    if (nonTrackedProducts?.length === fulfillmentItems.length) {
      setDisabledDropShip(true);
    }
    if (
      props?.documentDetails?.isPartialInvoice ||
      Utility.isParentDocumentConverted(fulfillmentItems, props.documentType)
    ) {
      setDisabledDropShip(true);
    }

    // if (fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP) {
    //   showLoader();
    //   RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
    // }

    // Temporary Hide DROP SHIP & PPS option for SO.
    // if (docType === DOC_TYPE.SALES_ORDER) {
    //   setDisabledPPS(true);
    // }
  }, []);

  const hasReservedQuantity = () => {
    if (hasPpsQuantity()) {
      // need to check only directly reserved items and not pps reserved items
      return false;
    } else return props?.documentDetails?.reservedStock ?? false;
  };
  const checkIsLocalizedUomQty = () => {
    return props?.documentDetails?.items?.some(
      (item: any) => item.isLocalizedUomQty
    );
  };
  const hasPpsQuantity = () => {
    let returnValue = false;
    if (props?.documentDetails?.items) {
      props.documentDetails.items.forEach((item: any) => {
        if (item.qtyConvertedToPps > 0) {
          returnValue = true;
        }
      });
    }
    return returnValue;
  };

  const hasNormalFulfillmentQuantity = () => {
    let returnValue = false;
    if (props?.documentDetails?.items) {
      props.documentDetails.items.forEach((item: any) => {
        let qtyFulfilled = item.quantityFulfilled;
        if (item.fulfilledQuantity) {
          if (isNaN(qtyFulfilled)) {
            qtyFulfilled = item.fulfilledQuantity;
          } else if (item.fulfilledQuantity && item.fulfilledQuantity > 0) {
            qtyFulfilled = item.fulfilledQuantity;
          }
        }
        if (
          qtyFulfilled -
            item.qtyFulfilledFromToDropShip -
            item.qtyFulfilledFromPps >
          0
        ) {
          returnValue = true; // if > 0 it has normal fulfillment
        }
      });
    }
    return returnValue;
  };

  const closePopup = (callBackType: any, data?: any) => {
    if (props.passingInteraction) {
      props.passingInteraction(
        {
          type: callBackType
        },

        data
      );
    }
  };

  const directFulfillment = (data: any) => {
    setIsApiLoading(true);
    setType('direct');
    if (docType === DOC_TYPE.INVOICE) {
      loadInvoiceDetails(data.salesInvoiceCode, 'direct');
    } else if (docType === DOC_TYPE.SALES_ORDER) {
      loadSalesOrderDetails(data.salesOrderCode, 'direct');
    } else {
      loadQuotationDetails(data.quotationCode, 'direct');
    }
  };

  const pickPackShip = () => {
    showLoader();
    if (docType === DOC_TYPE.SALES_ORDER) {
      const nonTrackedProducts = fulfillmentItems?.filter(
        (item: any) => item?.product?.type === PRODUCT_TYPE.NON_TRACKED
      );
      if (nonTrackedProducts && nonTrackedProducts.length > 0) {
        const alertButtonConfig = [
          {
            title: 'Later',
            className: 'bg-gray2 border-m mr-s',
            onClick: () => {
              removeLoader();
            }
          },
          {
            title: 'Yes',
            className: 'bg-button text-white',
            onClick: () => {
              RouteManager.navigateToPage(
                PAGE_ROUTES.PICK_PACK_SHIP,
                'picking-list'
              );
            }
          }
        ];
        showAlert(
          'Warning',
          'There are non-tracked items in the Sales Order. Non-tracked items can only be fulfilled using Direct Fulfillment. Please proceed if you wished to fulfill tracked items with Pick Pack Ship.',
          alertButtonConfig
        );
      } else {
        if (props.closeMenuContainer) {
          props.closeMenuContainer();
        }
        dispatch(removeDraft(props.openDraftId));
        PickPackShipService.apiConfig = {
          ...PickPackShipService.apiConfig,
          SearchTerm: ''
        };
        RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
      }
    } else {
      if (props.closeMenuContainer) {
        props.closeMenuContainer();
      }
      dispatch(removeDraft(props.openDraftId));
      PickPackShipService.apiConfig = {
        ...PickPackShipService.apiConfig,
        SearchTerm: ''
      };
      RouteManager.navigateToPage(PAGE_ROUTES.PICK_PACK_SHIP, 'picking-list');
    }
  };

  const setPopupTitle = (docType: DOC_TYPE) => {
    return docType === DOC_TYPE.INVOICE
      ? 'Invoice'
      : docType === DOC_TYPE.SALES_ORDER
      ? 'Sales Order'
      : getCapitalized(localizedText('quotation'));
  };

  // Load Quote Details
  const loadQuotationDetails = async (code: any, type: any) => {
    QuotationService.getQuoteByCode(code).then(
      (data: any) => {
        const quoteDetailsData: any = getUpdatedQuoteObject(data);
        setDocumentDetails(quoteDetailsData);
        if (!Utility.isEmpty(quoteDetailsData)) {
          setIsApiLoading(false);
          switch (type) {
            case 'dropship':
              dropShipSelectedOption(quoteDetailsData);
              break;
            case 'direct':
              closePopup(
                POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT,
                quoteDetailsData
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching quote details: ', err);
      }
    );
  };
  // Load Invoice Details
  const loadInvoiceDetails = async (code: any, type: any) => {
    InvoiceService.getInvoiceByCode(code).then(
      (data: any) => {
        const invoiceDetailsData: any = getUpdatedInvoiceObject(data);
        setDocumentDetails(invoiceDetailsData);
        if (!Utility.isEmpty(invoiceDetailsData)) {
          setIsApiLoading(false);
          switch (type) {
            case 'dropship':
              dropShipSelectedOption(invoiceDetailsData);
              break;
            case 'direct':
              closePopup(
                POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT,
                invoiceDetailsData
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching quote details: ', err);
      }
    );
  };

  // Load Sales Order Details
  const loadSalesOrderDetails = async (code: any, type: any) => {
    SalesOrderService.getSalesOrderByCode(code).then(
      (data: any) => {
        const salesOrderDetailsData: any = getUpdatedSalesOrderObject(data);
        setDocumentDetails(salesOrderDetailsData);
        if (!Utility.isEmpty(salesOrderDetailsData)) {
          setIsApiLoading(false);
          switch (type) {
            case 'dropship':
              dropShipSelectedOption(salesOrderDetailsData);
              break;
            case 'direct':
              closePopup(
                POPUP_CALLBACKS_TYPE.DIRECT_FULFILLMENT,
                salesOrderDetailsData
              );
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        setIsApiLoading(false);
        console.error('Error while fetching quote details: ', err);
      }
    );
  };

  const dropShipSelected = (data: any) => {
    if (!isShipToAddressValidForUSOrgs(data)) {
      return;
    }
    setIsApiLoading(true);

    setType('dropship');
    if (docType === DOC_TYPE.INVOICE) {
      loadInvoiceDetails(data.salesInvoiceCode, 'dropship');
    } else if (docType === DOC_TYPE.SALES_ORDER) {
      loadSalesOrderDetails(data.salesOrderCode, 'dropship');
    } else {
      loadQuotationDetails(data.quotationCode, 'dropship');
    }
  };
  const dropShipSelectedOption = (data: any) => {
    const buttons = [
      {
        title: 'Cancel',
        className: 'bg-gray1 border-m',
        onClick: () => {}
      },
      {
        title: 'Continue',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          let poDetails: any = Utility.createDropShip(data, docType);
          poDetails = { ...poDetails, isFromDropship: true };
          dispatch(
            createBlankDraft({
              payloadData: {
                title: LABELS.PURCHASE_ORDERS,
                type: LABELS.PURCHASE_ORDERS,
                tableId: draftsTableId,
                columnConfig: draftsColumnConfig,
                populateFormData: poDetails,
                isCenterAlign: true,
                isMaximized: true
              },
              draftType: DraftTypes.NEW
            })
          );
          closePopup(POPUP_CALLBACKS_TYPE.CLOSE_POPUP);
        }
      }
    ];

    showAlert(
      'Dropship confirmation!',
      `You have selected Dropship option for this ${setPopupTitle(
        docType
      )}. <br/>This will create a Purchase Order for ${localizedText(
        'Fulfillment'
      )} of this ${setPopupTitle(docType)}. <br/>Do you wish to continue?`,
      buttons
    );
  };

  const hasPpsConvertedQtyToFulfill = () => {
    let returnValue = false;
    props?.documentDetails?.items?.forEach((item: any) => {
      if (item.qtyConvertedToPps - item.qtyFulfilledFromPps != 0) {
        returnValue = true;
      }
    });
    return returnValue;
  };
  const hasQuantityToConvert = () => {
    let returnValue = false;
    props?.documentDetails?.items?.forEach((item: any) => {
      if (
        Utility.getQtyToFulfillAfterPartialFulfillments(
          item.productQuantity,
          item
        ) !== 0
      ) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  return (
    <div
      className="p-4"
      style={{
        pointerEvents: isApiLoading ? 'none' : 'auto',
        cursor: isApiLoading ? 'no-drop' : 'pointer'
      }}
    >
      <span className="text-1xl">
        You are about to {localizedText('Fulfill this')}{' '}
        {setPopupTitle(docType)}, please select an action below:
      </span>
      <ul className="py-4">
        {!disabledFulfillment && !hasPpsQuantity() && (
          <li
            className="cursor-hand border-radius-m listPickerBG cursor-hand border-m mt-3"
            onClick={() => {
              directFulfillment(document);
            }}
          >
            <div className="flex align-items-center">
              <div className="icon">
                <DKIcon
                  src={ic_fulfill_direct}
                  className="ic-r ic-xl"
                  style={{ opacity: 0.8 }}
                />
              </div>
              <div className="description" style={{ width: '90%' }}>
                <span className="fw-m text-blue">
                  {localizedText('Direct Fulfillment')}
                </span>
                <br />
                <span className="mt-1 text-gray">
                  {tenantInfo.country === 'IN'
                    ? `Dispatch Directly from Warehouse`
                    : `Fulfill within this ${setPopupTitle(docType)}`}
                </span>
              </div>
              {isApiLoading && type === 'direct' && (
                <div>
                  <DKSpinner
                    iconClassName="ic-r"
                    className="column pl-0 pr-s"
                  />
                </div>
              )}
            </div>
          </li>
        )}

        {!disabledDropship &&
          !hasReservedQuantity() &&
          !checkIsLocalizedUomQty() &&
          hasQuantityToConvert() && (
            <li
              className={
                isApiLoading
                  ? 'border-radius-m listPickerBG cursor-hand border-m mt-3 cursor-not-allowed'
                  : 'cursor-hand border-radius-m listPickerBG cursor-hand border-m mt-3'
              }
              onClick={() => {
                dropShipSelected(document);
              }}
            >
              <div className="flex align-items-center">
                <div className="icon">
                  <DKIcon
                    src={ic_fulfill_dropship}
                    className="ic-r ic-xl"
                    style={{ opacity: 0.8 }}
                  />
                </div>
                <div className="description" style={{ width: '90%' }}>
                  <span className="fw-m text-blue">Dropship</span>
                  <br />
                  <span className="mt-1 text-gray">
                    {localizedText('Fulfill with 3rd party logistics')}
                  </span>
                </div>
                {isApiLoading && type === 'dropship' && (
                  <div>
                    <DKSpinner
                      iconClassName="ic-r"
                      className="column pl-0 pr-s"
                    />
                  </div>
                )}
              </div>
            </li>
          )}

        {!disabledPPS &&
          ((!hasNormalFulfillmentQuantity() && !hasReservedQuantity()) ||
            hasPpsConvertedQtyToFulfill()) &&
          checkUserPermission(PERMISSIONS_BY_MODULE.PPS.MANAGE) && (
            <li
              className="cursor-hand border-radius-m listPickerBG cursor-hand border-m mt-3"
              onClick={pickPackShip}
            >
              <div className="flex align-items-center">
                <div className="icon">
                  <DKIcon
                    src={ic_fulfill_pps}
                    className="ic-r ic-xl"
                    style={{ opacity: 0.8 }}
                  />
                </div>
                <div className="description">
                  <span className="fw-m text-blue">Pick Pack Ship</span>
                  <br />
                  <span className="mt-1 text-gray">
                    {localizedText('Fulfill in the Warehouse Module')}
                  </span>
                </div>
              </div>
            </li>
          )}
      </ul>
    </div>
  );
};

export default FulfillmentConfirmation;
